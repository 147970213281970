
import { defineComponent } from 'vue'
import wallet from '@/lib/wallet'


export default defineComponent({
  name: 'BgBar',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isOpen: this.value,
      activePageMyArmy: false
    }
  },
  methods: {
    beforeEnter (el: any):void {
      el.style.height = '0'
    },
    start (el: any):void {
      el.style.height = `${el.scrollHeight}px`
    },
    end (el: any):void {
      el.style.height = ''
    },
    toggleClicked ():void {
      this.isOpen = !this.isOpen
      this.$emit('input', this.isOpen)
    },

    async connectWallet ():Promise<void> {
      if (this.activePageMyArmy == false) {
        try {
          if (await wallet.connectWallet()) {
            this.activePageMyArmy = true
          }
        } catch (e:any) {
          console.log(e?.name)
          console.log(e?.message)
          this.activePageMyArmy = false
        }
      }
    }
  },
  watch: {
    value (value) {
      this.isOpen = value
    }
  }
})
