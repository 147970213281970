
import { defineComponent } from 'vue'
import Header from './components/header/index.vue'
import Footer from './components/footer.vue'

setInterval(function(){
  const c = new Circle();
  c.createEl();
}, 50);

class Circle {
  createEl(){
    const div = document.createElement('div');
    div.innerHTML = `<svg viewBox="0 0 100 100"><rect class="circle" x="50" y="10" width="${this.randomNumber(3,4)}" height="${this.randomNumber(3,4)}" /></svg>`;
    div.style.top = this.randomNumber(1,100) + 'vh';
    div.style.left = this.randomNumber(1,100) + 'vw';
    const abd:any = document.querySelector('.section-hero-bubbles') as any
    abd.appendChild(div)

    const numb2 = this.randomNumber(5,30),
        numb3 = this.randomNumber(1,3),
        array: [string, string, string] = ['growFade1', 'growFade2', 'growFade3'];

    setTimeout(function(){
      div.classList.add( array[numb3] );
    },100);
  }
  randomNumber(min:number , max:number): any{
    return Math.floor( Math.random() * (max - min)) + min;
  }
}

export default defineComponent({
  components: {
    Header, Footer
  }
})
