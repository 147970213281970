
import { defineComponent } from 'vue'
import wallet from '@/lib/wallet'

export default defineComponent ({
  name: 'Btn',
  data () {
    return {
      activeBg: false,
      activePageMyArmy: false
    }
  },
  methods: {
    activeBgMenu ():void {
      this.activeBg = !this.activeBg
      this.$emit('activeBgMenu')
    },

    async connectWallet ():Promise<void> {
      if (this.activePageMyArmy == false) {
        try {
          if (await wallet.connectWallet()) {
            this.activePageMyArmy = true
          }
          console.log(wallet.onboardState())
        } catch (e:any) {
          console.log(e?.name)
          console.log(e?.message)
          this.activePageMyArmy = false
        }
      }
    }
  }
})
