import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1fab5c56"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navigation__btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.activePageMyArmy === false)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          href: "#",
          class: "primary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.connectWallet()))
        }, " Connect "))
      : _createCommentVNode("", true),
    (_ctx.activePageMyArmy === true)
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({name: 'Army'}))),
          class: "button secondary"
        }, " My Army "))
      : _createCommentVNode("", true),
    _createElementVNode("a", {
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.activeBgMenu())),
      class: _normalizeClass(["bg-menu", {'bg-menu--active': _ctx.activeBg === true}])
    }, " Menu ", 2)
  ]))
}