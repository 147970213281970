
import { defineComponent } from 'vue'

import Navigation from './components/navigation.vue'
import BgBar from './components/bg-bar.vue'
import Btn from './components/btn.vue'

export default defineComponent({
  name: 'Header',
  components: { Navigation, Btn, BgBar },
  data () {
    return {
      activeBg: false
    }
  }
})
