import Onboard from 'bnc-onboard'
import {ethers, BigNumber} from 'ethers'

import CyberBearzArmyMarketJSON from './contracts/CyberBearzArmyMarket.json'
import CybercolaJSON from './contracts/Cybercola.json'
import CyberSquadeJSON from './contracts/CyberSquade.json'

import {UserState} from 'bnc-onboard/dist/src/interfaces'

const BLOCKNATIVE_KEY = 'd16dc3a0-eebd-4480-8d88-096a6e501421'
const RPC_URL = 'https://bsc-dataseed.binance.org/'
const NETWORK_ID = 56

const CyberBearzArmyMarketInfo = {
    address: '0x467044e6A297084baAEBd53b6f1649C07527E273'
}

const CybercolaInfo = {
    address: '0xe6D766B46EF4C4B3f509e25e14478C5870205f0d',
    approve_GasLimit: 100_000
}

const CyberSquadeInfo = {
    address: '0x12572d7204cfe215FbEA1BB2f6aeDA7D50DdDeB9',
    claim_GasLimit: 1_000_000,
    createSquade_GasLimit: 1_000_000,
    rename_GasLimit: 500_000,
    changeSlogan_GasLimit: 1_000_000,
    disbandSquade_GasLimit: 1_000_000,
    extendSquadesAmount_GasLimit: 70_000
}


class Wallet {
    provider: ethers.providers.Web3Provider | ethers.providers.JsonRpcProvider = new ethers.providers.JsonRpcProvider(RPC_URL)

    cyberBearzArmyMarket = new ethers.Contract(
        CyberBearzArmyMarketInfo.address,
        CyberBearzArmyMarketJSON.abi,
        this.provider
    )

    cybercola = new ethers.Contract(
        CybercolaInfo.address,
        CybercolaJSON.abi,
        this.provider
    )

    cyberSquade = new ethers.Contract(
        CyberSquadeInfo.address,
        CyberSquadeJSON.abi,
        this.provider
    )

    onboard = Onboard({
        dappId: BLOCKNATIVE_KEY,
        networkId: NETWORK_ID,
        networkName: 'Binance Smart Chain',
        darkMode: true,

        subscriptions: {
            wallet: wallet => {
                console.log("New wallet detected: ", wallet)
                if (wallet.provider) {
                    this.provider = new ethers.providers.Web3Provider(wallet.provider, 'any')

                    this.cyberBearzArmyMarket = new ethers.Contract(
                        CyberBearzArmyMarketInfo.address,
                        CyberBearzArmyMarketJSON.abi,
                        this.provider.getUncheckedSigner()
                    )

                    this.cybercola = new ethers.Contract(
                        CybercolaInfo.address,
                        CybercolaJSON.abi,
                        this.provider.getUncheckedSigner()
                    )

                    this.cyberSquade = new ethers.Contract(
                        CyberSquadeInfo.address,
                        CyberSquadeJSON.abi,
                        this.provider.getUncheckedSigner()
                    )
                }
            },
            address: address => {
                console.log(`New address: ${address}`)
                if (address) {
                    window.localStorage.setItem('selectedAddress', address)
                } else {
                    window.localStorage.removeItem('selectedAddress')
                    // if (!window.localStorage.setItem('selectedAddress', address)) {
                    window.localStorage.removeItem('selectedWallet')
                    // }
                }
            }
        },

        walletSelect: {
            wallets: [
                {walletName: "metamask", preferred: true},
                {walletName: "trust", rpcUrl: RPC_URL, preferred: true},
                {walletName: "binance", preferred: true},
                {walletName: "walletConnect", rpc: {[NETWORK_ID]: RPC_URL}},
                {walletName: 'coinbase', preferred: true}
            ]
        }

    })

    async connectWallet(): Promise<any> {
        if (!(this.onboard.getState()).address) {
            const walletSelected = await this.onboard.walletSelect()

            if (!walletSelected) return false
        }

        const ready = await this.onboard.walletCheck()

        return ready
    }

    async onLoad() {
        const previouslySelectedWallet = window.localStorage.getItem('selectedWallet')

        if (previouslySelectedWallet != null) {
            await this.onboard.walletSelect(previouslySelectedWallet)
        }
    }

    async readyToTransact(): Promise<boolean> {
        if (!(this.onboard.getState()).address) {
            const walletSelected = await this.onboard.walletSelect()

            if (!walletSelected) return false
        }

        const ready = await this.onboard.walletCheck()

        return ready
    }

    // checksumAddress():string {
    //   return ethers.utils.getAddress(this.provider?.provider?.selectedAddress)
    // }

    onboardState(): UserState {
        return this.onboard.getState()
    }

    async disconnectWallet() {
        await this.onboard.walletReset()
    }

    checksumAddress() {
        return ethers.utils.getAddress((this.onboardState()).address)
    }

    async cybercola_balanceOf(): Promise<BigNumber> {
        return await this.cybercola.balanceOf(this.checksumAddress())
    }

    async cyberBearzArmyMarket_getBearzOfOwner(): Promise<number[]> {
        return await this.cyberBearzArmyMarket.getBearzOfOwner(this.checksumAddress())
    }

    async cyberBearzArmyMarket_tokenURI(tokenId: number): Promise<string> {
        return await this.cyberBearzArmyMarket.tokenURI(tokenId)
    }

    async cyberBearzArmyMarket_bearRank(tokenId: number): Promise<number> {
        return await this.cyberBearzArmyMarket.bearRank(tokenId)
    }

    async cyberSquade_getSquadProfitability(tokenIds: number[]): Promise<any> {
        return await this.cyberSquade.getSquadeProfitability(tokenIds)
    }

    async cyberSquad_claimable(): Promise<BigNumber> {
        return await this.cyberSquade.claimable(this.checksumAddress(), Math.floor(Date.now() / 1000))
    }

    async cyberSquad_currentDailyIncomeByAddress() {
        return await this.cyberSquade.currentDailyIncomeByAddress(this.checksumAddress())
    }

    async cyberSquad_claim() {
        const ready = await this.readyToTransact()

        if (!ready) return
        const accountData = this.onboardState()
        const gasLimit = CyberSquadeInfo.claim_GasLimit

        return await this.cyberSquade.connect(this.provider.getUncheckedSigner()).claim(
            {gasLimit: BigNumber.from(gasLimit.toString())})
    }

    async cyberSquade_priceRename(): Promise<BigNumber> {
        return await this.cyberSquade.priceRename()
    }

    async cyberSquade_priceDisbandSquade(): Promise<BigNumber> {
        return await this.cyberSquade.priceDisbandSquade()
    }

    async cyberSquade_priceChangeSlogan(): Promise<BigNumber> {
        return await this.cyberSquade.priceChangeSlogan()
    }

    async cyberSquade_priceExtendSquadesAmount(): Promise<BigNumber> {
        return await this.cyberSquade.priceExtendSquadesAmount()
    }



    async cyberSquade_byAddress() {
        return await this.cyberSquade.squadesByAddress(this.onboardState().address)
    }

    formatUnits(units: BigNumber): any {
        return +(ethers.utils.formatUnits(units, 18))
    }

    async cyberSquad_createSquade(tokenIds: number[]) {
        const ready = await this.readyToTransact()

        if (!ready) return
        const accountData = this.onboardState()
        const gasLimit = CyberSquadeInfo.createSquade_GasLimit

        return await this.cyberSquade.connect(this.provider.getUncheckedSigner()).createSquade(tokenIds, {gasLimit: BigNumber.from(gasLimit.toString())})
    }

    async cyberBearzMarket_setApprovalForAll(operator: string = CyberSquadeInfo.address, approval = true) {
        const ready = await this.readyToTransact()

        if (!ready) return
        const accountData = this.onboardState()
        const gasLimit = CyberSquadeInfo.createSquade_GasLimit

        return this.cyberBearzArmyMarket.connect(this.provider.getUncheckedSigner()).setApprovalForAll(operator, approval,
            {gasLimit: BigNumber.from(gasLimit.toString())})
    }

    async cyberBearzMarket_isApprovedForAll(address: string = this.onboardState().address, operator: string = CyberSquadeInfo.address) {
        return this.cyberBearzArmyMarket.isApprovedForAll(address, operator)
    }

    async cyberSquad_createSquade_Safe(tokenIds: number[]) {
        console.log(!await this.cyberBearzMarket_isApprovedForAll())
        if (!await this.cyberBearzMarket_isApprovedForAll()) {
            this.cyberBearzMarket_setApprovalForAll()
                .then(async (tx) => {
                    console.log(tx)
                    await this.cyberSquad_createSquade(tokenIds as any)
                })
                .catch(error => {
                    console.log(error)
                })
        } else {
            await this.cyberSquad_createSquade(tokenIds as any)
        }
    }

    async cyberSquad_squadesAmountLimits(address: string = this.onboardState().address): Promise<BigNumber> {
        return await this.cyberSquade.squadesAmountLimits(address)
    }

    async cyberSquad_squadeInfo(squadeId: number) {
        return await this.cyberSquade.squadeInfo(squadeId)
    }

    async cyberSquade_currentDailyIncomeBySquade(squadeId: number) {
        return await this.cyberSquade.currentDailyIncomeBySquade(squadeId)
    }

    async cybercola_totalSupply() {
        return await this.cybercola.totalSupply()
    }

    async cybercola_setApprovalForAll(operator: string = CyberSquadeInfo.address, amount = 0) {
        if (amount == 0) {
            amount = await this.cybercola_totalSupply()
        }
        const ready = await this.readyToTransact()

        if (!ready) return
        const accountData = this.onboardState()
        const gasLimit = CybercolaInfo.approve_GasLimit

        return this.cybercola.connect(this.provider.getUncheckedSigner()).approve(operator, amount,
            {gasLimit: BigNumber.from(gasLimit.toString())})
    }

    async cybercola_allowance(address: string = this.onboardState().address, spender: string = CyberSquadeInfo.address) {
        return await this.cybercola.allowance(address, spender)
    }

    async cyberSquade_rename(squadId: number, newName: string) {
        const ready = await this.readyToTransact()

        if (!ready) return
        const gasLimit = CyberSquadeInfo.rename_GasLimit

        const price: BigNumber = await this.cyberSquade_priceRename()
        const allowance: BigNumber = await this.cybercola_allowance()

        if (price.gt(allowance)) {
            await this.cybercola_setApprovalForAll()
        }

        return await this.cyberSquade.connect(this.provider.getUncheckedSigner()).rename(squadId, newName, {gasLimit: BigNumber.from(gasLimit.toString())})
    }

    async cyberSquade_changeSlogan(squadId: number, newSlogan: string) {
        const ready = await this.readyToTransact()

        if (!ready) return
        const gasLimit = CyberSquadeInfo.changeSlogan_GasLimit

        const price: BigNumber = await this.cyberSquade_priceChangeSlogan()
        const allowance: BigNumber = await this.cybercola_allowance()

        if (price.gt(allowance)) {
            await this.cybercola_setApprovalForAll()
        }

        return await this.cyberSquade.connect(this.provider.getUncheckedSigner()).changeSlogan(squadId, newSlogan, {gasLimit: BigNumber.from(gasLimit.toString())})
    }

    async cyberSquade_disbandSquade(squadeId: number) {
        const ready = await this.readyToTransact()

        if (!ready) return
        const gasLimit = CyberSquadeInfo.disbandSquade_GasLimit

        const price: BigNumber = await this.cyberSquade_priceDisbandSquade()
        const allowance: BigNumber = await this.cybercola_allowance()

        if (price.gt(allowance)) {
            await this.cybercola_setApprovalForAll()
        }

        return await this.cyberSquade.connect(this.provider.getUncheckedSigner()).disbandSquade(squadeId, {gasLimit: BigNumber.from(gasLimit.toString())})
    }

    async cyberSquade_extendSquadesAmount() {
        const ready = await this.readyToTransact()

        if (!ready) return
        const gasLimit = CyberSquadeInfo.extendSquadesAmount_GasLimit

        const price: BigNumber = await this.cyberSquade_priceExtendSquadesAmount()
        const allowance: BigNumber = await this.cybercola_allowance()

        if (price.gt(allowance)) {
            await this.cybercola_setApprovalForAll()
        }
        return await this.cyberSquade.connect(this.provider.getUncheckedSigner()).extendSquadesAmount(1, {gasLimit: BigNumber.from(gasLimit.toString())})
    }

}

const wallet = new Wallet()

export default wallet