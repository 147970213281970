import { ITokenData, IStateData, IUserSquade } from '@/helpers/interface'
import wallet from '@/lib/wallet'
import {BigNumber} from "ethers";

export default {
  actions: {
    async fetchToken(ctx:any): Promise<void> {

      const userTokenIds:number[] = await wallet.cyberBearzArmyMarket_getBearzOfOwner();

      userTokenIds.forEach(async (tokenId: number): Promise<void> => {

        const tokenURI: string = await wallet.cyberBearzArmyMarket_tokenURI(tokenId),
              bearRank: number = await wallet.cyberBearzArmyMarket_bearRank(tokenId)

        const tokenData: ITokenData = {
          tokenId: +tokenId,
          bearRank: bearRank,
          tokenURI: tokenURI,
          metadata: await fetch(tokenURI).then(data => { return data.json() })
        }

        ctx.commit('addUserToken', tokenData)
      })


    },

    async fetchUserSquades(ctx:any): Promise<void> {
      const squadeIds:number[] = await wallet.cyberSquade_byAddress()
      ctx.commit('setSquadeIds', squadeIds)

      const squadeTokens: { [id: number]: ITokenData } = {}
      squadeIds.forEach( async (squadeId: number) => {
        const squadeInfo = await wallet.cyberSquad_squadeInfo(squadeId)
        ctx.commit('setUserSquades', {
          squadeId: squadeId,
          combination: squadeInfo.Combination,
          owner: squadeInfo.Owner,
          name: squadeInfo.Name,
          slogan: squadeInfo.Slogan,
          bearz: squadeInfo.Bearz,
          bearRanks: squadeInfo.BearRanks,
          withdrawn: squadeInfo.Withdrawn,
          creationDate: squadeInfo.CreationDate,
          dailyIncome: await wallet.cyberSquade_currentDailyIncomeBySquade(squadeId)
        })
        squadeInfo.Bearz.forEach( async (tokenId: any) => {
          const tokenURI: string = await wallet.cyberBearzArmyMarket_tokenURI(tokenId),
              bearRank: number = await wallet.cyberBearzArmyMarket_bearRank(tokenId)

          ctx.commit('setUserSquadeTokens',{
            tokenId: +tokenId,
            bearRank: bearRank,
            tokenURI: tokenURI,
            metadata: await fetch(tokenURI).then(data => { return data.json() })
          })
        })
      })

      const newCybersquadsAllowedBN: BigNumber = await wallet.cyberSquad_squadesAmountLimits()
      const newCybersquadsAllowedQuantity: number = newCybersquadsAllowedBN.toNumber()
      const userSquadesIdsQuantity = squadeIds.length
      const newCybersquadsAllowed = newCybersquadsAllowedQuantity - userSquadesIdsQuantity

      ctx.commit('setNewCybersquadsAllowed', newCybersquadsAllowed)
    },
  },

  mutations: {
    addUserToken(state: IStateData, tokenData: ITokenData): void {
      state.userTokens.push(tokenData.tokenId)
      state.userTokensData[tokenData.tokenId] = tokenData
    },

    addingSelectedTokens(state:any, tokens:any): void {
      state.chosenTokens = tokens
    },

    setUserSquadeTokens(state: IStateData, tokenData: ITokenData): void {
      state.squadeTokens[tokenData.tokenId] = tokenData
    },

    setUserSquades(state: IStateData, squadeData: IUserSquade): void {
      state.userSquades[squadeData.squadeId] = squadeData
    },

    setSquadeIds(state: IStateData, squadeIds: number[]): void {
      state.squadesIds=squadeIds
    },

    setNewCybersquadsAllowed(state:IStateData, newCybersquadsAllowedamount: number): void {
      state.newCybersquadsAllowed = newCybersquadsAllowedamount
    }
  },

  state: {
    userTokens: [],
    userTokensData: {},
    chosenTokens:{},
    userSquades: {},
    squadesIds: [],
    squadeTokens: {},
    newCybersquadsAllowed: 0
  },


  getters: {
    userTokensData(state: IStateData):ITokenData {
      return state.userTokensData
    },

    receivedSelectedTokens(state: any):any {
      return state.chosenTokens
    },

    getUserSquadesIds(state: IStateData):number[] {
      return state.squadesIds
    },

    getUserSquades(state: IStateData):any {
      return state.userSquades
    },

    getSquadeTokens(state: IStateData):any {
      return state.squadeTokens
    },

    getNewCybersquadsAllowed(state: IStateData): number {
      return state.newCybersquadsAllowed
    }
  }
}